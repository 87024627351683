//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STable, Ellipsis, XCard } from '@/components'
import { flowableDoneTaskPage } from '@/api/modular/flowable/taskDoneManage'
import { flowableCategoryList } from '@/api/modular/flowable/categoryManage'
import moment from 'moment'
import tracking from '../../tracking/tracking'

export default {
  components: {
    XCard,
    STable,
    Ellipsis,
    tracking
  },
  data () {
    return {
      // 高級搜索 展開/關閉
      advanced: false,
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [
        {
          title: '流程名稱',
          dataIndex: 'procIns.name',
          scopedSlots: { customRender: 'procInsName' }
        },
        {
          title: '任務名稱',
          dataIndex: 'name'
        },
        {
          title: '發起人',
          dataIndex: 'procIns.startUserName'
        },
        {
          title: '發起時間',
          dataIndex: 'procIns.formatStartTime',
          scopedSlots: { customRender: 'formatStartTime' }
        },
        {
          title: '辦理時間',
          dataIndex: 'formatEndTime',
          scopedSlots: { customRender: 'formatEndTime' }
        },
        {
          title: '任務歷時',
          dataIndex: 'duration'
        }
      ],
      // 加載數據方法 必須為 Promise 對象
      loadData: parameter => {
        const dates = this.queryParam.dates
        if (dates != null) {
          this.queryParam.searchBeginTime = moment(dates[0]).format('YYYY-MM-DD HH:mm:ss')
          this.queryParam.searchEndTime = moment(dates[1]).format('YYYY-MM-DD HH:mm:ss')
          if (dates.length < 1) {
            delete this.queryParam.searchBeginTime
            delete this.queryParam.searchEndTime
          }
        }
        const obj = JSON.parse(JSON.stringify(this.queryParam))
        delete obj.dates
        return flowableDoneTaskPage(Object.assign(parameter, obj)).then((res) => {
          return res.data
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      flowableCategoryListData: []
    }
  },
  created () {
    this.flowableCategoryList()
    this.columns.push({
      title: '操作',
      width: '150px',
      dataIndex: 'action',
      scopedSlots: { customRender: 'action' }
    })
  },
  methods: {
    moment,
    /**
     * 獲取流程類型
     */
    flowableCategoryList () {
      flowableCategoryList().then((res) => {
        this.flowableCategoryListData = res.data
      })
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
